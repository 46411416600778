@tailwind base;
@tailwind components;
@tailwind utilities;

/* Works on Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #b4bcc3 white;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 12px;
}

*::-webkit-scrollbar-track {
    background: white;
}

*::-webkit-scrollbar-thumb {
    background-color: #b4bcc3;
    border-radius: 20px;
    border: 3px solid white;
}


#root {
    height: 100vh;
    display: flex;
    flex: 1;
    flex-direction: column;
}

.donut {
    display: block;
    width: 60px;
    height: 60px;
}

.table {
    table-layout: auto !important;
}


.table thead tr th {
    font-size: 0.875rem;
    color: rgb(99, 115, 129);
    font-weight: 600;
    padding: 16px;
}

.table tfoot tr th {
    font-size: 0.875rem;
    color: black;
    font-weight: 600;
    padding: 16px;
}



.table tbody tr td {
    padding: 10px 16px;
}

.table tbody tr:hover {
    background-color: #f4f6f8;
    cursor: default;
}

/* .table tbody tr td:last-child,
.table thead tr th:last-child {
    position: sticky;
    background-color: white;
    right: -1px
}

.table thead tr th:last-child {
    background-color: #f4f6f8;
} */

.table thead {
    position: sticky;
    background-color: #f4f6f8;
    top: 0;
    z-index: 9;
}

.breadcrum h2:last-child {
    color: #1d4ed8;
}

.breadcrum h2:not(:last-child):after {
    content: ">";
    padding: 0 6px;

}

.sr-only {
    visibility: hidden;
}


.bg {
    padding-top: 70px;
    padding-bottom: 30px;
    background-image: url("~/public/service-bg.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% auto;
    position: relative;
}

.s_bar span.sr-only {
    display: none;
}

#mod_table table,
th,
td {
    border: 1px solid lightblue;
    border-collapse: collapse;
}

#mod_table td:nth-child(1) {
    width: 160px;
}


/* styles.css */

/* Tailwind CSS classes */
.row {
    display: flex;
    flex-wrap: wrap;
}

.col-md-6,
.col-xs-6,
.col-sm-6,
.col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
}

.btn {
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    cursor: pointer;
}

.btn-default {
    background-color: #ffffff;
    border-color: #d1d3e2;
    color: #333;
}

.btn-secondary {
    background-color: #6c757d;
    border-color: #6c757d;
    color: #fff;
}

.dropdown {
    position: relative;
}

.dropdown-menu {
    display: none;
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 1000;
    min-width: 100%;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #333;
    text-align: left;
    background-color: #fff;
    border: 1px solid #d1d3e2;
    border-radius: 0.25rem;
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
}

.page-item {
    margin-right: 0.25rem;
}

.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #333;
    background-color: #fff;
    border: 1px solid #d1d3e2;
    cursor: pointer;
    text-decoration: none;
}

.page-link:hover {
    background-color: #f8f9fa;
}


/* Show the dropdown menu when the button is hovered or focused */
.dropdown:hover .dropdown-menu,
.dropdown:focus .dropdown-menu {
    display: block;
}

/* Hide the dropdown menu by default */
.dropdown-menu {
    display: none;
}

/* Additional custom styles if needed */