/* ChatApp.css */

.chat-app-container {
    display: flex;
    /* Use 100% of the viewport height */
}

.input-container {
    flex: 2;
    /* Half of the screen */
    padding: 20px;
}

input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
}

.iphone-container {
    flex: 1;
    /* Half of the screen */
    display: flex;
    justify-content: center;
    align-items: center;
}

.iphone-frame {
    position: relative;
    width: 55%;
    /* Adjust the width of the iPhone frame */
    padding-bottom: 100%;
    /* Maintain 2:1 aspect ratio for iPhone frame */
    background-color: #fff;
    border-radius: 40px;
    box-shadow: 0 0 0 7px rgb(0 0 0);
    overflow: hidden;
    /* Hide content that overflows the frame */
}

.notch {
    width: 30px;
    height: 7px;
    background-color: #333;
    position: absolute;
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px;
}

.iphone-screen {
    position: absolute;
    top: 10%;
    bottom: 10%;
    left: 10%;
    right: 10%;
    display: flex;
    align-items: start;
    justify-content: end;
}

.imessage {
    background-color: #eff6ff;
    padding: 10px;
    border-radius: 20px;
    font-size: 15px;
    max-width: 90%;
    /* Limit the width of the message to prevent overflowing on smaller screens */
    word-wrap: break-word;
}

/* Media Query for hiding iPhone screen on mobile view */
@media screen and (max-width: 1200px) {
    .iphone-container {
        display: none;
    }
}